import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { logo, homeIcon, subscriptionIcon, invoiceIcon, homeIconActive, subscriptionIconActive, invoiceIconActive ,logoutIcon, checkedIcon } from "./assets";
import  {getStorageData} from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  currentPage:number;
  logo:string;
  homeIcon:string;
  subscriptionIcon:string;
  invoiceIcon:string;
  homeIconActive:string;
  subscriptionIconActive :string;
  invoiceIconActive:string;
  logoutIcon:string;
  checkedIcon:string;
  mobileOpen:boolean;
  isMobile:number;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
    ];

    this.state = {
      currentPage:1,
      logo:logo.default,
      homeIcon:homeIcon.default,
      subscriptionIcon:subscriptionIcon.default,
      invoiceIcon:invoiceIcon.default,
      homeIconActive:homeIconActive.default,
      subscriptionIconActive:subscriptionIconActive.default,
      invoiceIconActive:invoiceIconActive.default,
      logoutIcon:logoutIcon.default,
      checkedIcon:checkedIcon.default,
      mobileOpen:false,
      isMobile: window.innerWidth,
    };
    this.handleResize = this.handleResize.bind(this);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    try {

      const token = await getStorageData("token");

      if (!token) {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
      }
    } catch (error) {

    }
  }
  

  async componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    this.setState({ isMobile: window.innerWidth });
  }

  handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({ currentPage: value });
  };
  handleDrawerToggle = () => {
    this.setState((prevState: any) => ({ mobileOpen: !prevState.mobileOpen }));
  };
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  handleLogout=()=>{
    localStorage.removeItem("token");
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  // Customizable Area End
}
